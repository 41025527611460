.footer {
    display: flex;
    justify-content: space-between;
    padding: 60px 0;
    font-size: 16px;
    line-height: 26px;
    color: #3D4E5C;
}

.footer_write_me {
    display: flex;
    gap: 24px;
}


.footer_write_me_email a {
    color: #4CAEFF;
    text-decoration: none;
    transition: .3s;
}
.footer_write_me_email a:hover {
    color: #006FCC;
}


@media screen and (max-width: 1024px){
    .footer_write_me {
        flex-direction: column;
        width: 100%;
        gap: 8px
    }
    .footer_copyright {
        display: none;
    }


}