@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 0;
  min-width: 0;
}

body {
  font-family: 'Montserrat', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 1300px;
  width: 90%;
  margin: 0 auto;
  position: relative;
}

h1 {
  font-weight: 700;
  font-size: 64px;
  line-height: 74px;
  color: #1E272E;
}

h3 {
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #1E272E;
}

h4 {
  font-size: 28px;
  line-height: 38px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1E272E;
}
h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #3D4E5C;
}
h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #3D4E5C;
}
img {
  max-width: 100%;
  height: auto;
}
.text_paragraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #3D4E5C;
}
.menu_links {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #1E272E;
}

.mt40 {
  margin-top: 40px;
}
.mt22 {
  margin-top: 22px;
}
.mt24 {
  margin-top: 24px;
}
.mt8 {
  margin-top: 8px;
}
.mt16 {
   margin-top: 16px;
 }
.mt18 {
  margin-top: 18px;
}
.mt60 {
  margin-top: 60px;
}
.mt44 {
  margin-top: 44px;
}
.mt80 {
  margin-top: 80px;
}
.mt70 {
  margin-top: 70px;
}
.mt74 {
  margin-top: 74px;
}
.mb90 {
  margin-bottom: 90px;
}
.mt32 {
  margin-top: 32px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}
.mt90 {
  margin-top: 90px;
}
.m90 {
  margin: 90px 0;
}
.mt180 {
  margin-top: 180px;
}
.mt36 {
  margin-top: 36px;
}

.w650 {
  width: 650px!important;
}
.w440 {
  width: 440px!important;
}
.w520 {
  width: 520px!important;
}
.w468 {
  width: 468px;
  max-width: 100%;
}
.ml1em {
  margin-left: 1em;
}
.ml2em {
  margin-left: 2em;
}
.p90 {
  padding: 90px 0;
}

.clwhite {
  color: #fff;
}
.clblue {
  color: #4CAEFF;
}
.bckgblue {
  background-color: #4CAEFF;

}
.mt106 {
    margin-top: 106px;
}

@media screen and (max-width: 744px){
  .mt90 {
    margin-top: 70px;
  }
  .mt180 {
    margin-top: 140px;
  }
  .w650,
  .w520,
  .w440 {
    width: 100%!important;
  }
  .desktop_only {
    display: none!important;
  }
}

@media screen and (max-width: 500px){
  .mt90 {
    margin-top: 50px;
  }
  .mt180 {
    margin-top: 100px;
  }
  .w650 {
    width: 100%!important;
  }
}

.list li {
  list-style: none;
}
.list li::before {
  content: "\2022";
  color: #C2CDD6;
  font-weight: bold;
  border: 0;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.text_title {
  text-transform: capitalize;
  color: #1E272E;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
}

.accent {
  font-size: 28px;
  line-height: 38px;
  text-transform: capitalize;
  color: #4CAEFF;

}