.project_about .menu_links {
    color: #668299;
}

.table {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 25px 75px rgba(159, 195, 224, 0.12);
    border-radius: 8px;
}

.table > div {
    display: flex;
}

.table > .table_header_row > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    margin-left: 2px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 10px 20px;
    background-color: #4CAEFF;
}

.table > .table_header_row > div:first-child {
    justify-content: left;
    padding-left: 40px;
    border-top-left-radius: 8px;
}

.table > .table_header_row > div:last-child {
    border-top-right-radius: 8px;
}


.table_row {
    display: flex;
    justify-content: center;
}

.table_row > div {
    width: 25%;
    margin-left: 2px;
    margin-top: 12px;
    padding: 16px 32px;
    font-size: 14px;
    line-height: 24px;
}

.table_row > div:first-child {
    align-items: flex-start;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #1E272E;
}
.guide_slider {
    display: flex;
    gap: 10%;
    /*justify-content: space-between;*/
}
.guide_slide {
    max-width: 640px;
    box-shadow: -30px 30px 74px rgba(30, 70, 129, 0.05);
}


.guide_slide_container {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
}

.slider_arrows {
    display: flex;
    align-items: center;
}

.slider_arrows span {
    margin: 0 23px;
    font-size: 36px;
    line-height: 46px;
}
.slider_arrows > div {
    cursor: pointer;
    position: relative;
    transition: .5s;

}

.slider_arrows > div:first-child:hover .arrow_square1 {
    transition: .3s;
    width: 80px;
    height: 80px;
    background: #EBF6FF;
    box-shadow: 0px 25px 75px rgba(121, 170, 210, 0.12);
    border-radius: 8px;
    position: absolute;
    top: -28px;
    left: -28px;
    z-index: -1;
}
.slider_arrows > div:last-child:hover .arrow_square2 {
    transition: .3s;
    width: 80px;
    height: 80px;
    background: #EBF6FF;
    box-shadow: 0px 25px 75px rgba(121, 170, 210, 0.12);
    border-radius: 8px;
    position: absolute;
    top: -28px;
    right: -28px;
    z-index: -1;
}


.user_card {
    width: 640px;
    display: grid;
    /*flex-direction: column;*/
}

.person_info_container {
    grid-row: 1/2;
    grid-column: 1/3;
    border-radius: 8px 8px 0 0;
    display: flex;
    background-color: #4CAEFF;
    padding: 25px 30px;
}

.person_info_container > img {
    flex: 1 0 auto;
    border-radius: 8px;
}

.person_info {
    margin-left: 30px;
}

.person_profession {
    margin-top: 3px;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.person_line {
    height: 2px;
    width: 100%;
    background-color: #FFFFFF;
    margin: 8px 0 16px;
}

.person_quote {
    margin-left: 24px;
    font-size: 16px;
    line-height: 26px;
    font-style: italic;
    font-weight: 500;
}

.persona {
    width: 100%;
}

.person_text {
    font-size: 16px;
    line-height: 26px;
    margin-top: 12px;
}

.person_describe {
    display: flex;
    width: 640px;
    align-items: stretch;

}
.person_describe > div {
    width: 50%;

}

.person_painpoints {
    background-color: #F6F8F9;
}
.person_goals {
    grid-column: 1/2;
    grid-row: 2/3;
}
.person_painpoints {
    grid-column: 2/3;
    grid-row: 2/3;
}
.person_painpoints,
.person_goals {
    padding: 30px;
}
.person_painpoints ul,
.person_goals ul {
    margin-left: 14px;
}
.person_painpoints ul li + li,
.person_goals ul li + li {
    margin-top: 8px;

}
.person_painpoints ul li,
.person_goals ul li {
    list-style: none;
    font-size: 14px;
    line-height: 24px;
    word-break: break-word;
}




.design_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.design_kano {
    display: flex;
}

.design_kano1 {
    padding: 44px;
    background: #FFFFFF;
    /* shadow */

    box-shadow: 0 25px 75px rgba(121, 170, 210, 0.12);
    border-radius: 8px;
}

.design_kano_title {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: #008CFF;
    z-index: 2;
    user-select: none;
}
.design_kano1:before {
    position: absolute;
    content: '';
    top: 28px;
    left: 0;
    width: 136px;
    height: 120px;
    background-color: #D9EEFF;
    z-index: 0;
    border-radius: 4px;
}
.design_kano_title:after {
    position: absolute;
    top: 44px;
    left: 44px;
    content: attr(data-text);
}
.design_kano1 {
    max-width: 480px;
}
.design_kano_list li + li {
    margin-top: 12px;
}
.design_kano_list li {
    font-size: 14px;
    line-height: 24px;
    color: #3D4E5C;
}

.design_kano > div {
    position: relative;
    /*border: 1px solid silver;*/
    box-shadow: 0px 25px 75px rgba(121, 170, 210, 0.12);
    border-radius: 8px;
}

.design_kano_square {
    position: absolute;
    top: 28px;
    left: 0;
    width: 136px;
    height: 120px;
    background-color: #D9EEFF;
    z-index: 0;
}
.design_kano2_square {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 94px;
    height: 120px;
    border-radius: 4px;
    background-color: #D9EEFF;
    z-index: 0;
}

.design_kano2 {
    margin-left: 35px;
    /*padding: 38px 0 38px 70px;*/
    position: relative;
    width: 123px;
}

.design_kano2 .title_rotated {
    font-weight: 600;
    display: flex;
    font-size: 36px;
    line-height: 46px;
    color: #008CFF;
    width: 400px;
    margin-bottom: 80px;
    position: absolute;
    bottom: 170px;
    left: -151px;
    transform: rotate(-90deg);
}


@media screen and (max-width: 1350px) {
    .design_kano2 {
        display: none;
    }
}
@media screen and (max-width: 1130px) {
    .user_card {
        width: 100%;
    }
    .person_info_container > img {
        flex: 0 1 174px;
    }
    .guide_slider {
        display: block;
    }
    .slick-track {
        /*width: auto!important;*/
    }
    .person_info {
        width: 70%;
    }
    .right_part {
        display: none;
    }
    .guide_slide {
        max-width: 100%;
    }
    .person_describe,
    .person_info_container {
        width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .design_block {
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;
    }
}
@media screen and (max-width: 900px){
    .segments {
        display: none;
    }
}

@media screen and (max-width: 744px){
    .project_about h1 {
        font-size: 64px;
        line-height: 74px;
    }
    
    .design_kano2 {
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .person_info_container {
        grid-column: 1/2;
    }
    .person_goals {
        grid-column: 1/2;
        grid-row: 2/3;

    }
    .person_painpoints {
        grid-column: 1/2;
        grid-row: 3/4;
        background-color: #fff;
    }
    .person_info_container {
        flex-direction: column;
        gap: 40px

    }
    .person_info_container > img {
        flex: 1 0 auto;
    }
    .person_info {
        width: 100%;
        margin-left: 0;
    }
    .person_info_container {
        border-radius: 8px;
    }
    .guide_slide {
        box-shadow: none;
    }
    .person_painpoints, .person_goals {
        margin-top: 50px;
        padding: 0;
    }
}

@media screen and (max-width: 500px){
    .project_about h1 {
        font-size: 48px;
        line-height: 58px;
    }
    .project_about .menu_links {
        font-size: 12px;
    }
    .design_title {
        font-size: 24px;
        line-height: 34px;
    }
}
