.about_outer_block {
    width: calc(100% - 66px);
    margin-left: 66px;
}

.about_me_image  {
    width: 529px;
}
.about_me_text {
    width: calc(100% - 589px);
}
.about_me_block {
    gap: 60px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1360px){

    .about_me_block_name h1 {
        font-size: 78px;
        line-height: 88px;
    }
}
@media screen and (max-width: 1250px){
    .about_me_block {
        gap: 30px;
    }
    .about_me_image {
        width: 469px;
    }

    .about_me_text {
        width: calc(100% - 469px);
    }
}
.about_me_footer_column {
    width: 50%;
}

@media screen and (max-width: 1150px){

    .about_me_block_name h1 {
        font-size: 68px;
        line-height: 78px;
    }
}


.about_me_emoji {
    margin: 35px 0;
}


.about_me_emoji {
    width: 60px;
    height: 60px;
    overflow: hidden;

}

.about_me_emoji img {
    width: 60px;
    height: 60px;
}

.about_me_emoji_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    animation: emoji_animation 8s infinite;
    animation-delay: 1s;
    animation-timing-function: ease-out;
}


.about_me_quote {
    max-width: 600px;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #3D445C;
    padding-left: 20px;
    border-left: 4px solid #66BAFF;
}

.about_me_line {
    height: 2px;
    background-color: #E7E9EF;
    margin: 60px 0;
}

.about_me_footer {
    display: flex;
    justify-content: space-between;
}
/*.about_me_footer_column:nth-child(1) {*/
/*    max-width: 260px;*/
/*}*/
/*.about_me_footer_column:nth-child(4) {*/
/*    width: 355px;*/
/*}*/


.about_me_footer_column_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #14171F;
}

.about_me_footer_column_line {
    width: 40px;
    height: 4px;
    background-color: #66BAFF;
    margin: 20px 0;
}

.about_me_footer_column_address {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-transform: capitalize;
    color: #3D445C;
}

.about_me_footer_column_degree {
    font-size: 16px;
    line-height: 26px;
    color: #3D445C;
    margin-top: 6px;
}

.about_me_footer_column_year {
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    margin-top: 6px;
    color: #3D445C;
}

.footer_column_list {
    list-style: none;
}

.footer_column_list li + li {
    margin-top: 6px;
}

.footer_column_list li {
    font-size: 16px;
    line-height: 26px;
    color: #3D445C;
}


@media screen and (max-width: 1024px){
    .about_me_quote {
        width: 90%;
    }
    .about_outer_block {
        width: 100%;
        margin-left: 0;
    }
    .about_me_block {
        flex-direction: column-reverse;
    }
    .about_me_block_name h1 {
        font-size: 64px;
        line-height: 74px;
    }
    .about_me_text {
        width: 100%;
    }
    .about_me_image {
        width: 100%;
    }
    .about_me_image img {
        width: 100%;
    }

    .about_me_emoji {
        margin: 24px 0;
    }
    .about_me_footer {
        flex-wrap: wrap;
        gap: 40px;
    }
    .about_me_footer > div {
        /*width: 50%;*/
    }
    .about_me_footer_column{
        /*max-width: 100%;*/
        width: calc(50% - 40px);
    }
    .about_me_line {
        margin: 40px 0;
    }
}

@media screen and (max-width: 500px) {
    .about_me_block_name h1 {
        font-size: 48px;
        line-height: 58px;
    }
    .about_me_quote {
        width: 100%;
    }

    .about_me_text {
        width: 100%;
    }
}

@media screen and (max-width: 455px) {
    .about_me_footer_column {
        width: 100%;
    }
}